export default function simpleData(){

    const jobCategory = [
        {value:"engineering",alias:"Engineering"},
        {value:"health_care",alias:"Health care"},
        {value:"software_engineering",alias:"Software engineering"},
        {value:"humanitarian_ngo",alias:"Humanitarian / Ngo"},
        {value:"media_comms",alias:"Media & Comms"},
        {value:"finance",alias:"Finance"},
        {value:"operations",alias:"Operations"},
        {value:"legal",alias:"Legal"},
        {value:"real_estate",alias:"Real estate"},
        {value:"education",alias:"Education"},
        {value:"information_technology",alias:"Information technology"},
        {value:"marketing",alias:"Marketing"},
        {value:"sales",alias:"Sales"},
        {value:"research",alias:"Research"},
        {value:"supply_chain",alias:"Supply chain"},
        {value:"catering",alias:"Catering"},
        {value:"building_architecture",alias:"Building & Architecture"},
        {value:"sales",alias:"Sales"},
        {value:"arts_and_design",alias:"Arts and design"},
        {value:"other",alias:"other"}
    ]

    return {
        jobCategory
    }
}
