<template>
    <!-- form -->
    <form class="validate-form" @submit.prevent="setJobDescriptionAction">
    <!-- core job detail -->
    <base-card-plain>
        <template #header>
            <h3 class="card-title">Enter basic job requirements</h3>
        </template>
        <template #default>
                <div class="row">
                    <div class="col-12 col-sm-12" v-if="!createNewJD">
                        <div class="mb-1">
                            <label class="form-label" for="basicSelect">Select existing JD</label>
                            <select class="form-select" id="basicSelect">
                                <option>Frontend engineering</option>
                                <option>Backend engineering</option>
                                <option>Other</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.title.msg?'text-danger':''" for="jobTitle">
                                Job title*
                                <span v-if="fv.title.msg" > | {{fv.title.msg}}</span>
                            </label>
                            <input  @keyup="validate.validateFormData(fv,'title',true)" v-model="fv.title.val"
                                type="text" class="form-control" placeholder="Enter Job title"
                                id="jobTitle" name="jobTitle" />
                        </div>
                    </div>
                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.category.msg?'text-danger':''" for="category">
                                Job Category
                                <span v-if="fv.category.msg" > | {{fv.category.msg}}</span>
                            </label>
                            <select class="form-select" @change="checkJobCategory" id="category" v-model="fv.category.val">
                                <option value="">Select</option>
                                <option v-for="category in info.jobCategory"
                                        :key="category.value" :value="category.value"
                                 > {{ category.alias }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12" v-if="otherJobCategory">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.newCategory.msg?'text-danger':''"  for="newCategory">
                                Add job category
                                <span v-if="fv.newCategory.msg" > | {{fv.newCategory.msg}}</span>
                            </label>
                            <input type="text" class="form-control"  @keyup="validate.validateFormData(fv,'newCategory',true)" v-model="fv.newCategory.val"
                                  placeholder="Enter job category" id="newCategory" name="newCategory" />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.summary.msg?'text-danger':''" for="summary">
                                Job summary
                                <span v-if="fv.summary.msg" > | {{fv.summary.msg}}</span>
                            </label>
                            <textarea @keyup="validate.validateFormData(fv,'summary',true)" v-model="fv.summary.val"
                                class="form-control" id="summary"
                                rows="4" placeholder="Enter brief job summary">
                            </textarea>
                        </div>
                    </div>
                    <div class="col-md-12 col-12 mb-1">
                            <label class="form-label" :class="fv.responsibility.msg?'text-danger':''" for="responsibility">
                                Responsibility
                                <span v-if="fv.responsibility.msg" > | {{fv.responsibility.msg}}</span>
                            </label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Add new responsibility" v-model.trim="newResponsibility"
                                aria-describedby="button-addon2" />
                                <button @click="addResponsibility" class="btn btn-secondary" id="button-addon2" type="button">
                                    <i class="bi bi-plus bs-icon-btn" ></i>
                                </button>
                            </div>

                            <div class="mt-1">
                                    <base-list-tag tagColor="secondary"  v-for="task in fv.responsibility.val" :key="task">
                                         <div class="float-start" >{{task}}</div>
                                         <span @click= "deleteResponsibility(task)" type="button" class="btn-close"
                                               data-dismiss="alert" aria-label="Close">
                                         </span>
                                    </base-list-tag>
                            </div>
                    </div>

                    <div class="col-md-12 col-12 mb-1">
                            <label class="form-label" :class="fv.requirement.msg?'text-danger':''" for="requirement">
                                Requirements / Qualifications
                                <span v-if="fv.requirement.msg" > | {{fv.requirement.msg}}</span>
                            </label>
                            <div class="input-group">
                                <input type="text" id="requirement" class="form-control"
                                        placeholder="Add new job requirement" v-model.trim="newRequirement"
                                aria-describedby="button-addon2" />
                                <button @click="addRequirement" class="btn btn-secondary" id="button-addon2" type="button">
                                    <i class="bi bi-plus bs-icon-btn" ></i>
                                </button>
                            </div>

                            <div class="mt-1">
                                    <base-list-tag tagColor="secondary"  v-for="req in fv.requirement.val" :key="req">
                                         <div class="float-start" >{{ req }}</div>
                                         <span @click= "deleteRequirement(req)" type="button" class="btn-close text-warning"
                                               data-dismiss="alert" aria-label="Close">
                                         </span>
                                    </base-list-tag>
                            </div>
                    </div>


                    <div class="col-12 col-sm-12 mb-1">
                            <label class="form-label" :class="fv.skill.msg?'text-danger':''"  for="techSkill">
                                Technical skill relevant to this job*
                                <span v-if="fv.skill.msg" > | {{fv.skill.msg}}</span>
                            </label>
                            <div class="input-group">
                                <input type="text" class="form-control"
                                       placeholder="Add technical skills relevant to this job"
                                       v-model.trim="newSkill"
                                       aria-describedby="button-addon2" id="techSkill" />
                                <button @click="addSkill" class="btn btn-secondary" id="button-addon2" type="button">
                                    <i class="bi bi-plus bs-icon-btn" ></i>
                                </button>
                            </div>
                            <div class="mt-1 cursor-pointer">
                                <base-badge badgeColor="secondary"
                                            v-for="skill in fv.skill.val"
                                            :key="skill"
                                            @click= "deleteSkill(skill)">
                                      {{ skill }}
                                    <span> x </span>
                                </base-badge>
                            </div>
                    </div>

                    <div class="col-12" v-if="fv.reqFeedback.msg">

                        <base-list-tag :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                    </div>





                    <div class="col-12" v-if="fv.showSubmit.val">
                        <base-button btnColor="primary" >Save information</base-button>
                    </div>
                    <base-spinner  v-else></base-spinner>

                </div>
        </template>
    </base-card-plain>
    <!--Core job detail -->


    </form>
    <!--/ form -->
</template>

<script>
// import core vue functions
import { ref,reactive,inject } from 'vue';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import validators from '@/hooks/validation';
import simpleData from '@/hooks/simpleData';

export default{
    props:{
        currJobId:{
            default:"none",
            type: String,
            required: false
        },
        isJobAd:{
            default:false,
            type: Boolean,
            required: false
        },
        isUpdateJobAd:{
            default:false,
            type: Boolean,
            required: false
        }
    },
    setup(props){

        // get the global vuex store values
        const store = useStore();

        // initiate route and router functions
        const router = useRouter();
        // const route = useRoute();

        // set the validators function
        const validate = validators();

        // get the axios object
        const axios = inject('axios');  // inject axios

        //get the urls
        const coreUrl = inject('coreUrl');

        // get the credentials data from store
        const credentials = store.getters["auth/getAuthCredentials"];

        // set the validators function
        const info = simpleData();

        const createNewJD = ref(true);
        const duplicateExistingJD = ref(false);

        // use the dispatch function to call an action that calls a mutation
        // to set the data of for the job ad
        const oldJobData = ref(store.getters["recruitment/getJobAd"]);

        const updateMode = props.isUpdateJobAd?true:false;
        // form values to be used in this form
        const fv = reactive({
            title : {status:false, val:updateMode?oldJobData.value.title:"",
                     msg:false, validate:["safeSpaceComma","required",
                                            {option:"textMin",val:3},
                                            {option:"textMax",val:100}]},
            category : {status:false, val:updateMode?oldJobData.value.category:"", msg:false, validate:["required"]},
            newCategory : {status:false, val:"", msg:false, validate:["safeSpaceInput",
                                                                   "required",
                                                                   {option:"textMin",val:3},
                                                                   {option:"textMax",val:50}
                                                                  ]},
            summary : {status:false, val:updateMode?oldJobData.value.summary:"", msg:false, validate:["required",
                                                                   {option:"textMin",val:30},
                                                                   {option:"textMax",val:400}
                                                                  ]},
            responsibility : {status:false, val:updateMode?JSON.parse(oldJobData.value.responsibility):[], msg:false, validate:["requiredArray"]},
            requirement : {status:false, val:updateMode?JSON.parse(oldJobData.value.requirement):[], msg:false, validate:["requiredArray"]},
            skill : {status:false, val:updateMode?JSON.parse(oldJobData.value.skill):[], msg:false, validate:["requiredArray"]},
            reqFeedback : {status:false, val:"", msg:false},
            showSubmit : {status:false, val:true}
        });


        const otherJobCategory = ref(false);

        function checkJobCategory(){

            validate.validateFormData(fv,'category',true);

            if(fv.category.val == "other"){
                otherJobCategory.value = true;
            }else{
                otherJobCategory.value = false;
                fv.newCategory.val = "";
                fv.newCategory.msg = false;
            }
        }

        // add responsibility
        // remove org task option
        function deleteResponsibility(task){
            let newResponsibility = fv.responsibility.val;
            fv.responsibility.val = newResponsibility.filter(item => item !== task);
            validate.validateFormData(fv,"responsibility",true);
        }


        // for adding new organization task
        const newResponsibility =  ref("");
        function addResponsibility()
        {
            let addResponsibility = Array.isArray(fv.responsibility.val)?fv.responsibility.val:[];

            if(validate.isSafeSpaceComma(newResponsibility.value) && validate.isRequired(newResponsibility.value)){
                addResponsibility.push(newResponsibility.value);
                fv.responsibility.val = addResponsibility;
                newResponsibility.value="";
                validate.validateFormData(fv,"responsibility",true);
            }else{
                fv.responsibility.msg = "only alphabets and numbers are allowed";
            }

        }

        // end add responsibility


        // add requirement
        function deleteRequirement(req){
            let newRequirement = fv.requirement.val;
            fv.requirement.val = newRequirement.filter(item => item !== req);
            validate.validateFormData(fv,"requirement",true);
        }


        // for adding new requirement
        const newRequirement =  ref("");
        function addRequirement()
        {
            let addRequirement = Array.isArray(fv.requirement.val)?fv.requirement.val:[];

            if(validate.isSafeSpaceComma(newRequirement.value) && validate.isRequired(newRequirement.value)){
                addRequirement.push(newRequirement.value);
                fv.requirement.val = addRequirement;
                newRequirement.value="";
                validate.validateFormData(fv,"requirement",true);
            }else{
                fv.requirement.msg = "only alphabets and numbers are allowed";
            }

        }

        // end update requirement

        // add requirement
        function deleteSkill(skill){
            let newSkill = fv.skill.val;
            fv.skill.val = newSkill.filter(item => item !== skill);
            validate.validateFormData(fv,"skill",true);
        }


        // for adding new requirement
        const newSkill =  ref("");
        function addSkill()
        {
            let addSkill = Array.isArray(fv.skill.val)?fv.skill.val:[];

            if(validate.isSafeSpaceInput(newSkill.value) && validate.isRequired(newSkill.value)){
                addSkill.push(newSkill.value);
                fv.skill.val = addSkill;
                newSkill.value="";
                validate.validateFormData(fv,"skill",true);
            }else{
                fv.skill.msg = "only alphabets and numbers are allowed";
            }

        }

        // end update requirement

        // start create  JD
        async function createJobDescription(){

            // start the action of making a submission, and show loader
            fv.showSubmit.val = false;

            const jdCreateUrl = props.isJobAd?'create_job_ad_jd':'create_job_description';

            // load the form data , and append all the necessary fields
            let formData = new FormData();
            formData.append("fv_client_id", credentials.accountId);
            formData.append("fv_host_id", credentials.accountHostId);
            formData.append("fv_title", fv.title.val);
            formData.append("fv_category", fv.category.val == "other" ? fv.newCategory.val : fv.category.val);
            formData.append("fv_summary", fv.summary.val);
            formData.append("fv_responsibility", fv.responsibility.val.length > 0?JSON.stringify(fv.responsibility.val):"");
            formData.append("fv_requirement",  fv.requirement.val.length > 0?JSON.stringify(fv.requirement.val):"");
            formData.append("fv_skill",  fv.skill.val.length > 0?JSON.stringify(fv.skill.val):"");

            // post the data using axios
            try{

                const response = await axios.post(
                    coreUrl.backendUrl+jdCreateUrl,
                    formData,
                    {
                        withCredentials: true,
                        headers:{
                           "Content-Type": "multipart/form-data"
                        }
                    }
                );

                // If successful, get the resppnse data
                const responseData = response.data;

                if(!props.isJobAd){

                    fv.title.val = "";
                    fv.category.val = "";
                    fv.newCategory.val = "";
                    fv.summary.val = "";
                    fv.responsibility.val = [];
                    fv.requirement.val = [];
                    fv.skill.val = [];

                }

                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = responseData.message.req_msg;
                fv.reqFeedback.val = "success";
                fv.showSubmit.status = false;

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;

                if(props.isJobAd){
                    router.push('/recruitment_update_job_ad/'+responseData.data);
                }


            }catch(error){

                // load the error to the appropriate field
                const fv_msg = error.response.data.message.fv_msg;
                const req_msg = error.response.data.message.req_msg;

                for(const key in fv_msg){
                    if(fv[key]){
                        fv[key]["status"] = false;
                        fv[key]["msg"] = fv_msg[key];
                    }
                }


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = req_msg;
                fv.reqFeedback.val = "danger";

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;

            }

        }
        // end create JD

        // start create  JD
        async function updateJobDescription(){

            // start the action of making a submission, and show loader
            fv.showSubmit.val = false;

            const jdCreateUrl = props.isJobAd?'update_job_ad_jd/'+props.currJobId:'update_job_description/'+props.currJobId;

            // load the form data , and append all the necessary fields
            let formData = new FormData();
            formData.append("fv_title", fv.title.val);
            formData.append("fv_category", fv.category.val == "other" ? fv.newCategory.val : fv.category.val);
            formData.append("fv_summary", fv.summary.val);
            formData.append("fv_responsibility", fv.responsibility.val.length > 0?JSON.stringify(fv.responsibility.val):"");
            formData.append("fv_requirement",  fv.requirement.val.length > 0?JSON.stringify(fv.requirement.val):"");
            formData.append("fv_skill",  fv.skill.val.length > 0?JSON.stringify(fv.skill.val):"");

            // post the data using axios
            try{

                const response = await axios.put(
                    coreUrl.backendUrl+jdCreateUrl,
                    formData,
                    {
                        withCredentials: true,
                        headers:{
                           "Content-Type": "multipart/form-data"
                        }
                    }
                );

                // If successful, get the resppnse data
                const responseData = response.data;


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = responseData.message.req_msg;
                fv.reqFeedback.val = "success";
                fv.showSubmit.status = false;

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;


            }catch(error){

                // load the error to the appropriate field
                const fv_msg = error.response.data.message.fv_msg;
                const req_msg = error.response.data.message.req_msg;

                for(const key in fv_msg){
                    if(fv[key]){
                        fv[key]["status"] = false;
                        fv[key]["msg"] = fv_msg[key];
                    }
                }


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = req_msg;
                fv.reqFeedback.val = "danger";

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;

            }

        }
        // end edit JD

        return {
            fv,
            validate,
            info,
            setJobDescriptionAction:updateMode?updateJobDescription:createJobDescription,
            createNewJD,
            duplicateExistingJD,
            otherJobCategory,
            checkJobCategory,
            newResponsibility,
            deleteResponsibility,
            addResponsibility,
            newRequirement,
            deleteRequirement,
            addRequirement,
            newSkill,
            deleteSkill,
            addSkill
        }
    }
}


</script>
